.loading-container {
    position: absolute;
    left: 50%;    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;    height: 100%;


    display: flex;    flex-direction: column;
    justify-content: center;    align-items: center;
    opacity: 2;    z-index: 13;

    cursor: pointer;

}

.background {
    position: absolute;
    left: 50%;    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;    height: 100%;

     background-color: #F0F0F0;
     background-image: url('/src/img/preview3.png');
     background-repeat: no-repeat;
     background-position: center;
     background-size: 95%;

    display: flex;    flex-direction: column;
    justify-content: center;    align-items: center;
    z-index: 13;
}

#startbutton{
    display: flex;
    color: rgb(255,255,255);
    color: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(231,231,231,1) 100%);
    z-index: 15;
    background: none;
    cursor: pointer;
    
    margin-left: 12.5px;
}

.circle {
    z-index: 14;
    background: rgb(38,135,197);
    background: radial-gradient(circle, rgba(38,135,197,1) 0%, rgba(35,118,172,1) 50%, rgba(30,100,146,1) 100%);
    
    height: 125px; width: 125px;
    display: flex; justify-content: center; align-items: center;

    border-radius: 50%;
    cursor: pointer;
    /* filter: drop-shadow( 2px 4px 4px rgba(0, 0, 0, .7)); */

}



/* @media screen and (max-width: 800px) {
    .background {
        position: absolute;
        left: 50%;    top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;    height: 100%;
    
    
         background-image: url('/src/img/preview_new.png');
         background-repeat: no-repeat;
         background-position: center;
         background-size: 160%;

    
        display: flex;    flex-direction: column;
        justify-content: center;    align-items: center;
        opacity: 0.6;    z-index: 11;
        }     

}

@media screen and (max-width: 480px) {
    .background {
        position: absolute;
        left: 50%;    top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;    height: 100%;
    
    
         background-image: url('/src/img/preview_new.png');
         background-repeat: no-repeat;
         background-position: center;
         background-size: 180%;

    
        display: flex;    flex-direction: column;
        justify-content: center;    align-items: center;
        opacity: 1;    z-index: 11;
        }    

} */