.menu {
    position: absolute;
    top: 20px;
    left: 20px;
    height: 46px;
    width: 46px;
  }
  
  .menu-link {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;    
  }
  
  .menu-icon {
    position: absolute;
    background: none;
    cursor: pointer; border: none;
    fill: white; border-radius: 50%;
  
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
  }


  /* ------------- */
  .menu-circle {
    background-color: #acb8c1; opacity: 0.88;
    width: 100%; height: 100%;

    position: absolute;
    z-index: 3; border-radius: 50%;

    display: flex; justify-content: center;

  }

  .menu-circle-opened {
    background-color: #acb8c1; opacity: 0.88;
    width: 100%; height: 100%;

    position: absolute;
    z-index: 3; border-radius: 50%;

    display: flex; justify-content: center;
  }

  .menu.open .menu-circle {
    transform: scale(60); 
    position: fixed;
    z-index: 4;
  }

  
  /* --- Content --- */
  .menu-overlay {
    background-color: #fff;
    color: #000;
    font-family: "Source Sans Pro", sans-serif;

    left: 0; top: 0;
    right: 0; bottom: 0;

    position: absolute; text-align: center;
    transition: opacity 0.2s ease-in-out;
    z-index: 4; opacity: 0;

    visibility: hidden; display: flex;
    flex-direction: column; align-items: center;
  }

  .menu-overlay.open {
    opacity: 1;
    visibility: visible;
  }

  #close {
    position: absolute;
    width: 20px; height: 20px;
    left: 10px; margin-top: 5px;
    cursor: pointer;
  }

  #imageMenu {
    width: 40px;
    -webkit-filter: drop-shadow( 9px 3px 2px rgba(0, 0, 0, .7));
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .3));
  }

  #settings {
    margin-top: 4vh;
    font-size: 26px;
    position: fixed;
  }

  h4{
    font-size: 24px;
    position: fixed;
    pointer-events: none;
  }

  #rot {
    margin-right: 50px;
  }

  #sha {
    margin-right: 105px;
  }

  #bac {
    top: 55vh;
  }

  /* --- Reset Button --- */

  .reset {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 46px;
    width: 46px;
  }

  .resetClicked {
    height: 50px; width: 300px;

    background: #2887c5; border-radius: 15px;

    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);    

    display: flex; flex-wrap: wrap;
    align-content: center; justify-content: center;
    
    display: none;
    opacity: 0;
  }

  .elementToFadeInAndOut {
    animation: fadeInOut 2s linear forwards;
  }

  @keyframes fadeInOut {
    0% { opacity:0; }
    50% { opacity:1; } 
    100% { opacity:0; } 
  }

  .resetClickedText {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    color: white;
  }


  /*************** MENU NAV BUTTONS ***************/
  :root {
    --main-color: #2887c5;
    --second-color: #fff;
  }

  .navigation-bar {
    opacity: 0.88;
    background: #acb8c1;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    display: flex;      overflow: hidden;
    justify-content: space-evenly;    
    
    bottom: 34.9%; width: 100%; position: fixed;
    font-family: "Source Sans Pro", sans-serif; font-size: 20px;
  }


  .navigation-bar .list-items {
    list-style: none;
    display: flex;
    position: relative;
  }
  .navigation-bar .list-items .pointer {
    position: absolute;
    left: 0px;
    height: 100%;
    width: 4.5rem;
    z-index: 0;
    transition: all 0.2s linear;
    margin-inline: 1.37vw;
  }
  .navigation-bar .list-items .pointer::before,
  .navigation-bar .list-items .pointer::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
  }

  .navigation-bar .list-items .pointer::after {
    bottom: 0;
    border-top: 8px solid var(--main-color);
    border-radius: 30px 30px 0 0;
  }

  .navigation-bar .list-items .item {
    flex: 1 1 0px;
    position: relative;
    z-index: 2;
  }
  .navigation-bar .list-items .item .link {
    display: inline-block; margin-inline: 1.37vw;
    height: 2.5rem;
    width: 4.5rem;
    line-height: 2rem;
    text-align: center;
    color: var(--second-color);
  }
  .navigation-bar .list-items .item.active .link {
    color: var(--main-color);
  }
  .navigation-bar .list-items .item .link i {
    font-size: 1.6rem;
    transition: font-size 0.2s linear;
  }
  .navigation-bar .list-items .item.active .link i {
    font-size: 1.4rem;
  }


  /*************** Resizen ***************/
    

  @media screen and (min-width: 480px) {
    
    .menu-overlay {
      border-radius: 25px;
  
      left: 25%; top: 15%;
      right: 25%; bottom: 15%;
  
      position: absolute; text-align: center;
      transition: opacity 0s ease-in-out;
      z-index: 4; opacity: 0;
  
      visibility: hidden; display: flex;
      flex-direction: column; align-items: center;
      
      background: rgb(255,255,255);
      background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(231,231,231,1) 100%);
    }
  
    .navigation-bar {
      display: none;
    }
  
    .menu {
      top: 4vh;
      left: 17.5vw;
      position: fixed;
    }
  
    .menu.open {
      position: absolute;
      top: 3.75vh;
    }
  
    .reset {
      top: 4vh;
      left: 5vw;
      position: fixed;
    }

    #rotationoption {
      top: 32.5vh;
    }

    #shadowoption {
      top: 42.5vh;
    }

    #settings {
      top: 15vh;
    }

    #bac {
      top: 57.5vh;
    }
  }


  @media screen and (max-width: 1100px) {

    .menu {
      left: 10vw;
    }

  }

  @media screen and (max-width: 780px) {

    .menu {
      left: 15vw;
    }
  }

  @media screen and (max-width: 480px) {
    
    #settings {
      top: 3.75vh;
    }

    .line2 {
      top: 250px;
    }
    
    #bac {
      top: 325px;
    }

    .menu {
      left: 20px;
    }
  
    .reset {
      right: 20px;
    }
  }