    /******************
       Arrow Animations 
    ******************/
    

    .arrow {
      transition-duration: 0.4s;
      -webkit-transition-duration: 0.4s; /* Safari */
  }
  
  .arrow:hover{
      transition: all 0.3s ease;
      -webkit-transform: scale(1.25);
              transform: scale(1.25);
  }

  .arrow:after {
    content: "";
    width: 3.67vh;
    height: 3.67vh;
    transition: all 0.3s
  }
  
  .arrow:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
  }

  
  .arrow:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top:0;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
  }
  
  .arrow:active:after {
    opacity: 1;
    transition: 5s;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  
  .arrow:active {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    transition: 0.2s;
  }


/***************************
  Circle-Icon Animations 
***************************/


.circle-icon {
  transition: all 0.6s ease;
}

.circle-icon:hover {
  transition: all 0.3s ease;
  fill: #2d2d2d;
  /* -o-transform: scale(1.25);
  -ms-transform: scale(1.25);
  -moz-transform: scale(1.25);
  -webkit-transform: scale(1.25);
          transform: scale(1.25); */
}

.circle-icon:after {
  content: "";
  width: 3.67vh;
  height: 3.67vh;
  transition: all 0.3s
}

.circle-icon:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s
}


/******************************
Buttons - General Animations 
******************************/


.button{
transition-duration: 0.4s;
-o-transition-duration: 0.4s; 
-ms-transition-duration: 0.4s; 
-moz-transition-duration: 0.4s; 
-webkit-transition-duration: 0.4s; /* Safari */
}

[data-radio-selected='true'] {
transition: all 0.3s ease;
box-shadow: 0 0 2px 2px #fff;
-o-transform: scale(1.15);
-ms-transform: scale(1.15);
-moz-transform: scale(1.15);
-webkit-transform: scale(1.15);
        transform: scale(1.15);
} 

.button:hover{
transition: all 0.3s ease;
box-shadow: 0 0 2px 2px #fff;
-o-transform: scale(1.15);
-ms-transform: scale(1.15);
-moz-transform: scale(1.15);
-webkit-transform: scale(1.15);
        transform: scale(1.15);
}

.button:after {
content: "";
width: 3.67vh;
height: 3.67vh;
transition: all 0.3s
}

.button:active:after {
padding: 0;
margin: 0;
opacity: 1;
transition: 0s
}


.button:after {
content: "";
display: block;
position: absolute;
left: 0;
top:0;
border-radius: 5px;
width: 100%;
height: 100%;
opacity: 0;
transition: all 0.5s;

}

.button:active:after {
box-shadow: 0 0 0 0 rgb(44, 41, 41);
position: absolute;
border-radius: 5px;
left: 0;
top:0;
opacity: 1;
-o-transform: scale(1);
-ms-transform: scale(1);
-moz-transform: scale(1);
-webkit-transform: scale(1);
transform: scale(1);
}

.button:active {
-o-transform: scale(1.1);
-ms-transform: scale(1.1);
-moz-transform: scale(1.1);
-webkit-transform: scale(1.1);
transform: scale(1.1);
transition: 0.2s;
}


/***************************
Radiobuttons - Animations
***************************/


.iconw {
left: 6vh;
position: fixed;
transition: 0.1s;
display: none;
}

.icong {
left: 6vh;
position: fixed;
transition: 0.1s;
display: none;
}

.iconr {
left: 6vh;
position: fixed;
transition: 0.1s;
display: none;
}


/***************************
Checkbox - Animations
***************************/


input[type="checkbox"] {
position: relative;
width: 60px;
height: 30px;
-webkit-appearance: none;
appearance: none;
background: #2d2d2d;
outline: none;
border-radius: 2rem;
cursor: pointer;
box-shadow: inset 0 0 5px rgb(0 0 0 / 50%);
}

input[type="checkbox"]::before {
content: "";
width: 30px;
height: 30px;
border-radius: 50%;
background: #fff;
position: absolute;
top: 0;
left: 0;
transition: 0.5s;
}

input[type="checkbox"]:checked::before {
transform: translateX(100%);
background: #fff;
}

input[type="checkbox"]:checked {
background: #2887c5;
}

@media screen and (max-width: 780px) {

.iconw {
left: 6vh;
position: fixed;
transition: 0.1s;
display: none;
}

.icong {
left: 6vh;
position: fixed;
transition: 0.1s;
display: none;
}

.iconr {
left: 6vh;
position: fixed;
transition: 0.1s;
display: none;
}}

@media screen and (max-width: 480px) {

  .iconw {
    left: 10vw;
  }

  .icong {
    left: 10vw;
  }

  .iconr {
    left: 10vw;
  }}